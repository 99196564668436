
  @import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');


.container {
  position: fixed;
  font-family: 'Tenor Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 4px;

}
.displayFlex {
    display: flex;
  }
  .justifyContentCenter {
    justify-content: center;
  }